import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
// import styles from '../css/aboutus.module.css';
import PageHeading from '../components/PageHeading/PageHeading';
import TitleBar from '../components/TitleBar/TitleBar';
import HtTiredImage from '../components/GatsbyImageComponents/HtTiredImage';

class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout>
        <Helmet
          title="VEYM | Maintenance"
          meta={[
            {
              name: 'description',
              content: `Maintenance`,
            },
          ]}
        />
        <PageHeading>Maintenance</PageHeading>

        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Maintenance</BreadcrumbItem>
          </Breadcrumb>
        </Container>

        <br />

        <TitleBar>VEYM Account Creation Temporarily Unavailable</TitleBar>
        <br />
        <Container>
          <br />
          The VEYM Account signup process is currently undergoing maintenance
          and is unavailable. If you have other issues regarding your VEYM
          account, please submit at ticket at{' '}
          <a href="https://support.veym.net">https://support.veym.net</a>
          .
          <br />
          <br />
          We apologize for the inconvenience. Have a blessed day!
          <HtTiredImage />
        </Container>
        <br />
        <br />
        <br />
      </Layout>
    );
  }
}

export default Maintenance;

// export const frontmatter = {
//   title: 'Maintenance',
//   category: 'pages',
//   description: 'Maintenance',
//   tags: 'maintenance ',
// };
